import React from "react";
// import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./home";
import NavBar from "./navBar";

import "./styles.css";
// import "./Professional_Profile_Picture_Headshot_Circle.png";
// import "./Israel_Background3.png";
// import "./Trey_Chesser_Icon.PNG";
// import "./Gator2.jpg";


function Podcast() {
  return (
    <div>
      <h1 className="podcastH1">Under Construction...</h1>
    </div>
  );
}

export default Podcast;
